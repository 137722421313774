import React from "react"
import "./footer.css"

function Footer() {
  return (
    <footer>
      <h6
        data-sal="fade"
        data-sal-duration="600"
        data-sal-delay="50"
        className="footer-text"
      >
        © Falconi Media
      </h6>
    </footer>
  )
}

export default Footer
