import React from "react"
import { Link } from "gatsby"
import "./header.css"
import { Divide as Hamburger } from "hamburger-react"

function Header({ burgerIsOpen, setBurgerIsOpen }) {
  return (
    <div
      data-sal="fade"
      data-sal-duration="600"
      data-sal-delay="50"
      className="navbar"
      // style=${setBurgerIsOpen ? {{ paddingBottom: "0px"}} : {{paddingBottom: "0px"}}
      style={{
        paddingBottom: burgerIsOpen ? "0px" : "5vh",
      }}
    >
      <div className="brand-title-container">
        <Link to="/" id="brand-title">
          FALCONI MEDIA
        </Link>
      </div>
      <div className="toggle-button">
        <Hamburger
          toggled={burgerIsOpen}
          direction="left"
          toggle={setBurgerIsOpen}
          size={17}
        />
      </div>
      <div className={`navbar-links ${burgerIsOpen ? "active" : ""}`}>
        <ul className="links">
          <li>
            <Link to="/reel">Reel</Link>
          </li>
          <li>
            <Link style={{ color: "red" }} to="/kaotica">
              Kaotica
            </Link>
          </li>
          <li>
            <Link to="/work">Work</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header
